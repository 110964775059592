import React from 'react'
import ResponsiveImage from './ResponsiveImage'
// import EuroSignIcon from '../../images/euro-sign.svg';
import CameraIcon from '../../images/camera.png'
import DecorationIcon from '../../images/decoration.png'
import CustomerReviewIcon from '../../images/customer-review.png'
import CameraCloudIcon from '../../images/saas.png'
import { navigate } from 'gatsby'

function goToReservation() {
  navigate('/photographie/contact')
}

const HeroSection = () => {
  return (
    <section className="hero-section">
      <div className="container">
        <div className="hero-content">
          <div className="hero-content-container">
            <p className="en-ce-moment">EN CE MOMENT</p>
            <h1>
              <span>CAPTUREZ</span> LA MAGIE DE{' '}
              <span>
                <br />
                NOËL
              </span>{' '}
              EN FAMILLE
            </h1>
            <p>
              Offrez-vous des souvenirs inoubliables avec une séance photo de
              Noël. Immortalisez les sourires et les moments chaleureux qui
              comptent vraiment.
            </p>
            <button className="btn-reserver" onClick={goToReservation}>
              Réserver
            </button>
            <ul className="hero-stats">
              <li>
                <strong>5</strong> Fonds disponibles
              </li>
              <li>
                <strong>25</strong> Créneaux disponibles
              </li>
              <li>
                <strong>5</strong> Clichés minimum
              </li>
            </ul>
          </div>
        </div>
        <div className="hero-image">
          <div className="cadre1"></div>
          <div className="cadre2"></div>
          <div className="cadre3"></div>
          <ResponsiveImage relativePath={`noel/herosection-bg.png`} />
        </div>
      </div>
    </section>
  )
}

const TarifsSection = () => {
  return (
    <section className="tarifs-section">
      <div className="tarifs-container">
        <h2>TARIFS</h2>
        <div className="tarif-box">
          {/* <img src={EuroSignIcon} alt="Tarif séance photo Noël" width="60" height="60" /> */}
          <p>
            <strong>5 photos</strong>
          </p>
          <p>50€</p>
        </div>
        <div className="tarif-box">
          {/* <img src={EuroSignIcon} alt="Tarif séance photo Noël" width="60" height="60" /> */}
          <p>
            <strong>8 photos</strong>
          </p>
          <p>80€</p>
        </div>
        <div className="tarif-box">
          <p>
            <strong>+5€</strong> <br />
            par photo supplémentaire
          </p>
        </div>
        <div className="tarif-box">
          <div className="code-promo">
            <p>
              <span className="nb">2</span>{' '}
              <span className="col-center">
                <strong>clichés</strong> offerts avec le code
              </span>{' '}
              <strong className="code">NOEL2024</strong>
            </p>
          </div>
          <button className="btn-reserver" onClick={goToReservation}>
            Réserver
          </button>
        </div>
      </div>
    </section>
  )
}

const ReassuranceSection = () => {
  return (
    <section className="reassurance-section">
      <div className="reassurance-item">
        <img src={CameraIcon} alt="Photographe professionnel" />
        <p>
          <strong>Photographe professionnel</strong>
        </p>
        <p>
          Pour des clichés parfaitement capturés, mettant en valeur chaque
          moment magique de Noël.
        </p>
      </div>
      <div className="reassurance-item">
        <img src={DecorationIcon} alt="Décors festifs inclus" />
        <p>
          <strong>Décors festifs inclus</strong>
        </p>
        <p>
          Des décors de Noël authentiques et enchanteurs créés spécialement pour
          vos photos, sans frais supplémentaires.
        </p>
      </div>
      <div className="reassurance-item">
        <img src={CustomerReviewIcon} alt="Satisfaction garantie" />
        <p>
          <strong>Satisfaction garantie</strong>
        </p>
        <p>
          Tous nos clients sont satisfaits des photos, avec 100% d&apos;avis 5
          étoiles.
        </p>
      </div>
      <div className="reassurance-item">
        <img src={CameraCloudIcon} alt="Livraison rapide" />
        <p>
          <strong>Livraison rapide</strong>
        </p>
        <p>
          Recevez vos photos en haute définition sous 7 jours après votre
          séance.
        </p>
      </div>
    </section>
  )
}

function NoelHeroSection() {
  return (
    <div className="noel-hero-section">
      <HeroSection />
      <TarifsSection />
      <ReassuranceSection />
    </div>
  )
}

export default NoelHeroSection
